import { Route, Routes } from "react-router-dom";

import TutorDetails from "Pages/TutorPages/classes/ClassDetails/details";
import Earning from "Pages/TutorPages/Earnings/Index";
import TutorCalender from "Pages/TutorPages/Calender/index";
import BatchRequest from "Pages/TutorPages/Batchrequest";
import BatchRequestDetails from "../../Pages/TutorPages/Batchrequest/Details/details";
import Courses from "Pages/TutorPages/Courses/courses";
import LessonPlan from "Pages/TutorPages/Courses/LessonPlan";
import Leave from "Pages/TutorPages/Leave/index";
import PPT from "Pages/TutorPages/Courses/PPT";
import UpdateProfile from "Pages/TutorPages/Profile/Updateprofile";
import Profile from "Pages/TutorPages/Profile/Profile/profile";
import Tutoravailability from "Pages/TutorPages/TutorAvailability/availability";
import BulkCancel from "Pages/TutorPages/BulkCancel/cancel";
import Notifications from "Pages/TutorPages/Notifications/Notifications";
import Dashboard from "Pages/TutorPages/Dashboard/dashboard";
import PayoutDetails from "Pages/TutorPages/Earnings/PayoutDetails";
import Resources from "Pages/TutorPages/Resource/Resource";
function TeacherRoutes() {
    return (
        <Routes>
            <Route path="Calender" element={<TutorCalender />} />

            <Route path="Classes/details" element={<TutorDetails />} />
            <Route path="Earnings" element={<Earning />} />
            <Route path="Earnings/Payout_Details" element={<PayoutDetails />} />
            <Route path="Courses" element={<Courses />} />
            <Route path="Courses/LessonPlan" element={<LessonPlan />} />
            <Route path="Batch-request" element={<BatchRequest />} />
            <Route path="Leave" element={<Leave />} />
            <Route path="profile" element={<Profile />} />
            <Route path="update-profile" element={<UpdateProfile />} />
            <Route path="PPT" element={<PPT />} />
            {/* <Route path="Resources" element={<Resources />} /> */}
            <Route path="Availability" element={<Tutoravailability />} />
            <Route
                path="Batch-request/Details"
                element={<BatchRequestDetails />}
            />
            <Route path="Bulk-cancel" element={<BulkCancel />} />
            <Route path="Resources" element={<Resources />} />
            <Route path="Notifications" element={<Notifications />} />
            <Route path="dashboard" element={<Dashboard />} />
        </Routes>
    );
}

export default TeacherRoutes;
