const timeConversion = (inputTimeString: any) => {
    // Parse the input time string
    const inputTime = new Date(`2000-01-01T${inputTimeString}`);

    // Convert to AM/PM format with local time zone
    const formattedTime = inputTime.toLocaleTimeString(undefined, {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
    });
    return formattedTime;
};

const DateTimeGenerator = (time: string, dateStr: string) => {
    const [hours, minutes] = time.split(":").map(Number);
    const formatted_date = new Date(dateStr);
    formatted_date.setUTCHours(hours);
    formatted_date.setUTCMinutes(minutes);
    const istOffset = 5.5 * 60 * 60 * 1000;
    return new Date(formatted_date.getTime() - istOffset);
};

export const CalenderDataGenerator = (data: any) => {
    let calenderData = data.map((item: any, index: any) => {
        return {
            id: index,
            start: DateTimeGenerator(
                item.Scheduled_StartTime,
                item.Scheduled_StartDate
            ),
            title: `${item.ClassStatus === 3 ? "✖" : ""} ${timeConversion(
                item.Scheduled_StartTime
            )} ${item.BatchName}`,
            end: DateTimeGenerator(
                item.Scheduled_EndTime,
                item.Scheduled_StartDate
            ),
            color:
                item.ClassStatus === 1
                    ? "Burlywood"
                    : item.ClassStatus === 2
                    ? "rgba(4, 135, 54, 0.96)"
                    : "red",
            TutorName: item.TutorName,
            BatchSize: item.BatchSize,
            BatchName: item.BatchName,
            CourseName: item.CourseName,
            ScheduleClassID: item.ScheduleClassID,
        };
    });

    return calenderData;
};
