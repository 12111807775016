import React from "react";
import ScheduleClasses from "./classes.module.scss";
import TableLoad from "./TableLoad/load";
import Moment from "react-moment";
import { ClassesProps } from "./types";
import { useNavigate } from "react-router";

const Classes: React.FC<ClassesProps> = ({
    classesData,
    Loading,
    rowSelect,
    handleCheckboxChange,
    handleSelectAll,
    selectedRows,
}) => {
    const Navigate = useNavigate();

    // PAGE NAVIGATION HANDLER TO DETAILS PAGE
    const classDetails = (event: any) => {
        if (!selectedRows) {
            const id = event?.currentTarget.getAttribute("id");
            Navigate(`/Tutor/Classes/details?id=${id}`);
        }
    };
    return (
        <>
            <h3>Classes</h3>

            {Loading ? (
                <TableLoad />
            ) : (
                <div className={ScheduleClasses["Table"]}>
                    <table>
                        <thead>
                            <tr>
                                {rowSelect && (
                                    <th>
                                        <input
                                            type="checkbox"
                                            checked={
                                                selectedRows &&
                                                selectedRows.length ===
                                                    classesData.length
                                            }
                                            onChange={handleSelectAll}
                                        />
                                    </th>
                                )}
                                <th>Class</th>

                                <th>Start Date Time</th>
                                <th>End Time</th>
                                <th>Duration</th>
                                <th>Course Details</th>
                                <th>Lesson Plan</th>
                                <th>Cancel Reason</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {classesData?.length > 0 ? (
                                classesData?.map((item: any, index: any) => {
                                    return (
                                        <tr
                                            id={item.ScheduleClassID}
                                            onClick={classDetails}>
                                            {rowSelect && (
                                                <td>
                                                    <input
                                                        type="checkbox"
                                                        checked={
                                                            selectedRows &&
                                                            selectedRows.includes(
                                                                item.ScheduleClassID
                                                            )
                                                        }
                                                        onChange={() => {
                                                            handleCheckboxChange(
                                                                item.ScheduleClassID
                                                            );
                                                        }}
                                                    />
                                                </td>
                                            )}
                                            <td>{item.ClassLessonName}</td>

                                            <td>
                                                <Moment format="DD-MM-YYYY">
                                                    {
                                                        new Date(
                                                            item.Scheduled_StartDate
                                                        )
                                                    }
                                                </Moment>{" "}
                                                <br />
                                                {String(
                                                    item.Scheduled_StartTime
                                                )}
                                            </td>
                                            <td>
                                                {String(item.Scheduled_EndTime)}
                                            </td>
                                            <td> {item.Duration} Min</td>
                                            <td>
                                                {item.CourseName} <br />
                                                <span
                                                    style={{
                                                        fontSize: "0.9rem",
                                                    }}>
                                                    {" "}
                                                    ({item.BatchSize})
                                                </span>
                                            </td>

                                            <td>
                                                {" "}
                                                {item?.LessonPlans_Class &&
                                                    item.LessonPlans_Class.join(
                                                        ","
                                                    )}
                                                {item?.PartiallyCompleted
                                                    ?.IsPartiallyCompleted ==
                                                    1 && (
                                                    <>
                                                        ,
                                                        {
                                                            item
                                                                ?.PartiallyCompleted
                                                                .LessonPlan
                                                        }
                                                        (
                                                        {
                                                            item
                                                                ?.PartiallyCompleted
                                                                .Slide
                                                        }
                                                        )
                                                    </>
                                                )}
                                            </td>

                                            <td
                                                style={{
                                                    wordBreak: "break-word",
                                                    maxWidth: "3rem",
                                                }}>
                                                {item.CancelReason}
                                            </td>
                                            <td>
                                                {item.ClassStatus === 1 ? (
                                                    <span
                                                        className={
                                                            ScheduleClasses[
                                                                "active-stat"
                                                            ]
                                                        }>
                                                        Pending
                                                    </span>
                                                ) : item.ClassStatus === 2 ? (
                                                    <span
                                                        className={
                                                            ScheduleClasses[
                                                                "completed"
                                                            ]
                                                        }>
                                                        Completed
                                                    </span>
                                                ) : (
                                                    <span
                                                        className={
                                                            ScheduleClasses[
                                                                "cancelled"
                                                            ]
                                                        }>
                                                        Cancelled By{" "}
                                                        {item.CancelledByType ===
                                                        1
                                                            ? "Admin"
                                                            : item.CancelledByType ===
                                                              2
                                                            ? "RM"
                                                            : item.CancelledByType ===
                                                              3
                                                            ? "Tutor"
                                                            : item.CancelledByType ===
                                                              4
                                                            ? "Parent"
                                                            : "SM"}
                                                    </span>
                                                )}
                                            </td>
                                        </tr>
                                    );
                                })
                            ) : (
                                <tr>
                                    <td
                                        colSpan={9}
                                        style={{ textAlign: "center" }}>
                                        No Record Found
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            )}
            {/* <TableLoad /> */}
        </>
    );
};

export default Classes;
