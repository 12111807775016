import React from "react";
import eventClasses from "./events.module.scss";
import { useNavigate } from "react-router-dom";
import { EventProps } from "./types";

const Events: React.FC<EventProps> = ({ event, setSelectedEvent }) => {
    const navigate = useNavigate();
    const navHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
        let id = e.currentTarget.getAttribute("custom-id");
        navigate(`/Tutor/Classes/details?id=${id}`);
    };

    return (
        <div className={eventClasses["event-container"]} id="event-details">
            <h3>{event?.BatchName}</h3>
            <div className={eventClasses["content"]}>
                <p>
                    Tutor : <span>{event?.TutorName}</span>
                </p>
                <p>
                    BatchSize : <span>{event?.BatchSize}</span>
                </p>
                <p>
                    CourseName : <span>{event?.CourseName}</span>
                </p>
            </div>
            <div className={eventClasses["actions"]}>
                <button custom-id={event?.ScheduleClassID} onClick={navHandler}>
                    More Details
                </button>
                <button
                    onClick={() => {
                        setSelectedEvent(null);
                    }}>
                    close
                </button>
            </div>
        </div>
    );
};

export default Events;
